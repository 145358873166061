import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getYueNews } from '../../../../../services/api';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * for this page
 */

class YueNews extends React.PureComponent {
    state = {
        news: [],
    };
    static propTypes = {};

    UNSAFE_componentWillMount() {
        this.getYueNews();
    }

    getYueNews = async () => {
        const data = await getYueNews('yueNews');

        // console.log(data);
        this.setState({
            news: data,
        });
    };

    render() {
        /**
         * 组件分发数据
         */
        const card = item => {
            const imgUrl =
                item.thumbnails && item.thumbnails.image && item.thumbnails.image.length !== 0
                    ? item.thumbnails.image[0].url
                    : '';

            const tags = () => {
                if (item.tags) {
                    const tagsArr = item.tags.split(' ');

                    return tagsArr.splice(0, 3).join(' ');
                } else {
                    return null;
                }
            };

            return (
                <div className={`${styles.picTxt01} clearfix`} key={item.id}>
                    {imgUrl ? (
                        <a href={item.url} target="_blank ">
                            <img title={item.title} alt={item.title} src={imgUrl} width="90 " height="65" border="0" />
                        </a>
                    ) : null}

                    <h3>
                        <a href={item.url} target="_blank " className={!imgUrl ? styles.fullWidth : ''}>
                            {item.title2 ? item.title2 : item.title}
                        </a>
                    </h3>
                    <div className={styles.Function} style={{ left: !imgUrl ? 0 : '104px' }}>
                        <div className={styles.time}>{tags()}</div>
                    </div>
                </div>
            );
        };

        return (
            <div className={styles.YueNews}>
                <div className={styles.col_L}>
                    <div className={styles.title400}>
                        <h3>
                            <a href="http://yue.ifeng.com/yuenews.shtml" target="_blank">
                                音乐资讯
                            </a>
                        </h3>
                    </div>
                    <div className={styles.box400}>
                        {this.state.news.map(item => {
                            return card(item);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueNews);
