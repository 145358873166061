import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/public_method';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getYueAi } from '../../../../../services/api';

/**
 * for this page
 */
import ChannelTab from '../../../../components/channelTab';

class YueAi extends React.PureComponent {
    state = {
        news: [],
    };
    static propTypes = {
        yueAiTitle: PropTypes.array,
    };

    UNSAFE_componentWillMount() {
        this.getYueNews();
    }

    getYueNews = async () => {
        const data = await getYueAi('yueAi');

        // console.log(data);
        this.setState({
            news: data,
        });
    };

    render() {
        // console.log('YueAi=', this.props);
        const { yueAiTitle } = this.props;

        /**
         * 组件分发数据
         */

        const listItem = (item, index) => {
            const imgUrl =
                item.thumbnails && item.thumbnails.image && item.thumbnails.image.length !== 0
                    ? item.thumbnails.image[0].url
                    : '';

            return index === 0 ? (
                <div className={styles.top} key={item.id}>
                    <h3>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title2 ? item.title2 : item.title}
                        </a>
                    </h3>
                    <a href={item.url} target="_blank" rel={rel}>
                        <img src={imgUrl ? formatImage(imgUrl, 220, 140) : ''} width="220" height="140" />
                    </a>
                    <div className={styles.bottomLine} />
                </div>
            ) : (
                <div className={styles.listItem} key={item.id}>
                    <h3>
                        <a href={item.url} target="_blank" rel={rel}>
                            {item.title2 ? item.title2 : item.title}
                        </a>
                    </h3>
                </div>
            );
        };

        return (
            <div className={`${styles.YueAi} clearfix`}>
                <div className={styles.col_L}>
                    <Chip id="30089" type="struct" title="音乐频道-乐评-文字导航" groupName="正文" content={yueAiTitle}>
                        <ChannelTab />
                    </Chip>
                    <div className={styles.box}>
                        <div className={styles.listBox}>
                            {this.state.news.map((item, index) => {
                                return listItem(item, index);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueAi);
