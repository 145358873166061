import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class YueNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('yueNav=', this.props);
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        return (
            <div className={styles.col_wbf}>
                <div className={styles.YueNav}>
                    <div className={styles.col_nav}>
                        <ul className={styles.clearfix}>
                            {content
                                ? content.map(item => {
                                      return (
                                          <li className={styles.li_ts} key={item.title}>
                                              <a href={item.url} target="_blank" title={item.title} rel={rel}>
                                                  {item.title}
                                              </a>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueNav);
