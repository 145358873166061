import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';

/**
 * for this page
 */
import Slide from './slide';
import YueNews from './yueNews';
import YueAi from './yueAi';
import YueHot from './yueHot';
import YueNishizhen from './yueNishizhen';
import YueMV from './yueMV/';
import YueShow from './yueShow/';
import YuePhotos from './yuePhotos/';
import YueAlbums from './yueAlbums';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        ads: PropTypes.object,
    };

    render() {
        // console.log('Content=', this.props);

        const {
            yueSlide,
            yueHot,
            yueNews,
            yueAi,
            yueNishizhen,
            yueAiTitle,
            yueNishizhenTitle,
            yueMV,
            yueShow,
            yuePhotos,
            yueAlbums,
        } = this.props.content;

        const { fullColAd1, fullColAd2, fullColAdB } = this.props.ads;
        /**
         * 组件分发数据
         */

        return (
            <div className={styles.Content}>
                <div className={`${styles.col} clearfix`}>
                    <div className={styles.col_LM}>
                        {/* 轮播图 */}
                        <div className={styles.slide}>
                            <Chip
                                id="55065"
                                type="recommend"
                                title="音乐频道-焦点图"
                                groupName="正文"
                                content={yueSlide}>
                                <Slide />
                            </Chip>
                        </div>
                        <div className={styles.colLM}>
                            {/* 音乐新闻 */}
                            <YueNews content={yueNews} />
                            {/* 音乐评论 */}
                            <YueAi content={yueAi} yueAiTitle={yueAiTitle} />
                        </div>
                    </div>
                    <div className={styles.col_R}>
                        {/* 热歌排行 */}
                        <Chip id="30080" type="struct" title="音乐频道-热歌排行" groupName="正文" content={yueHot}>
                            <YueHot handleTabsChange={this.handleTabsChange} />
                        </Chip>
                        {/* 音乐逆时针 */}
                        <YueNishizhen content={yueNishizhen} yueNishizhenTitle={yueNishizhenTitle} />
                    </div>
                </div>
                <div className={styles.ad_m_1}>
                    <Ad content={fullColAd1} />
                </div>
                <div className={`${styles.col} clearfix`}>
                    <div className={styles.col_LM}>
                        {/* 音乐MV */}
                        <Chip id="55066" type="recommend" title="音乐频道-音乐MV" groupName="正文" content={yueMV}>
                            <YueMV />
                        </Chip>
                    </div>
                    <div className={styles.col_R}>
                        {/* 演出 */}
                        <Chip id="30081" type="struct" title="音乐频道-演出" groupName="正文" content={yueShow}>
                            <YueShow />
                        </Chip>
                    </div>
                </div>
                <div className={styles.ad_m_1}>
                    <Ad content={fullColAd2} />
                </div>
                {/* 高清图片 */}
                <div className={`${styles.col} clearfix colp01`}>
                    <Chip id="55067" type="recommend" title="音乐首页-高清图片" groupName="正文" content={yuePhotos}>
                        <YuePhotos />
                    </Chip>
                </div>
                <div className={styles.ad_m_1}>
                    <Ad content={fullColAdB} />
                </div>
                <div className={`${styles.col} clearfix`}>
                    <Chip id="55068" type="recommend" title="音乐首页-专辑推荐" groupName="正文" content={yueAlbums}>
                        <YueAlbums />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Content);
