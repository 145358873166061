import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

/**
 * 音乐首页
 * @param {String} dataType 数据类型: yueNews, yueAi, yueNishizhen
 */
export const getYueNews = async dataType => {
    const data = await jsonp(`${apiBaseUrl}/yue/index/${dataType}/getYueNews`, {
        jsonpCallback: 'getYueNews',
        cache: 120,
    });

    const dataArr = data.data.data;

    const result = dataArr.slice(0, 10);

    return result ? result : null;
};
export const getYueAi = async dataType => {
    const data = await jsonp(`${apiBaseUrl}/yue/index/${dataType}/getYueAi`, {
        jsonpCallback: 'getYueAi',
        cache: 120,
    });

    const dataArr = data.data.data;

    const result = dataArr.slice(0, 15);

    return result ? result : null;
};
export const getYueNishizhen = async dataType => {
    const data = await jsonp(`${apiBaseUrl}/yue/index/${dataType}/getYueNishizhen`, {
        jsonpCallback: 'getYueNishizhen',
        cache: 120,
    });

    const dataArr = data.data.data;

    const result = dataArr.slice(0, 12);

    return result ? result : null;
};
