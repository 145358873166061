import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * for this page
 */

class ChannelTab extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        // console.log('ChannelTab=', this.props);
        const { content } = this.props;

        /**
         * 组件分发数据
         */

        return (
            <div className={styles.ChannelTab}>
                <div className={styles.tit04}>
                    <h3>
                        <a href={content[0].url} target="_blank">
                            {content[0].title}
                        </a>
                    </h3>
                </div>
            </div>
        );
    }
}

export default errorBoundary(ChannelTab);
