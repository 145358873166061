import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class YueHot extends React.PureComponent {
    state = {
        current: 0,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    mouseOverHandler(e) {
        const index = Number(e.currentTarget.attributes['index'].value);

        this.setState({
            current: index,
        });
    }

    render() {
        // console.log('YueHot=', this.props);
        const { content } = this.props;

        /**
         * 组件分发数据
         */
        const card = (item, index) => {
            return (
                <div key={index} index={index} onMouseEnter={e => this.mouseOverHandler(e)}>
                    <div className={this.state.current === index ? styles.hidden : styles.list_01}>
                        <div className={styles.num}>{index + 1 < 10 ? String(0) + (index + 1) : index + 1}</div>
                        <div className={styles.song}>{item.title}</div>
                        <div className={styles.name}>{item.singer}</div>
                    </div>
                    <div className={this.state.current === index ? `${styles.list_02} clearfix` : styles.hidden}>
                        <div className={styles.num01}>{index + 1 < 10 ? String(0) + (index + 1) : index + 1}</div>
                        <div className={styles.picTxtR}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={item.thumbnail} width="65" height="65" />
                            </a>
                            <div className={styles.picR01}>
                                <div className={styles.picR01_01}>
                                    <h3>
                                        <a href={item.url} target="_blank" rel={rel}>
                                            {item.title}
                                        </a>
                                    </h3>
                                    <p>{item.singer}</p>
                                </div>
                                <a href={item.url} target="_blank" rel={rel} className={styles.play} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div className={`${styles.YueHot} clearfix`}>
                <div className={styles.tit04}>
                    <h3>
                        <a href="javascript:void(0)">热歌排行</a>
                    </h3>
                </div>
                <div className={styles.box}>
                    {content.map((item, index) => {
                        return card(item, index);
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueHot);
