import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { formatImage } from '@ifeng-fe/public_method';
class Slide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        // console.log(this.props);
        const { content } = this.props;

        const sliderTmpl = function sliderTmpl(item) {
            return (
                <div>
                    <a
                        className={styles.blockA}
                        href={item.url}
                        target="_blank"
                        alt={item.title}
                        title={item.title}
                        rel={rel}>
                        <img
                            src={item.thumbnail ? formatImage(item.thumbnail, 660, 368) : ''}
                            style={{ width: '100%', height: 368 }}
                        />
                    </a>
                    <div className={styles.text_mask} />
                    <div className={styles.test}>
                        <a href={item.url} target="_blank" alt={item.title} title={item.title} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const sliderConfig = {
            arrows: true,
            autoplay: true,
            dots: true,
            dotsAction: 'hover',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            unitSize: 660,
            speed: 0,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl,
        };

        return (
            <div className={styles.sliderContainer}>
                <Slides content={content} config={sliderConfig} />
            </div>
        );
    }
}
export default errorBoundary(Slide);
