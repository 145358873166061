import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/public_method';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class YuePhotos extends React.PureComponent {
    state = {
        isShow: false,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    MouseEnter = {};

    render() {
        // console.log('YuePhotos=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */
        const card_l_s = item => {
            return (
                <div className={styles.p_l_01} key={item.id}>
                    <a target="_blank" href={item.url} rel={rel}>
                        <img width="198" height="148" alt={item.title} src={formatImage(item.thumbnail, 198, 148)} />
                    </a>
                    <div className={styles.bg} />
                    <div className={styles.pwText}>
                        <a target="_blank" href={item.url} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const card_l_l = item => {
            return (
                <div className={styles.p_l_02} key={item.id}>
                    <a target="_blank" href={item.url} rel={rel}>
                        <img width="398" height="148" alt={item.title} src={formatImage(item.thumbnail, 398, 148)} />
                    </a>
                    <div className={styles.bg} />
                    <div className={styles.pwText}>
                        <a target="_blank" href={item.url} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const card_m_l = item => {
            return (
                <div className={styles.p_l_0b}>
                    <a target="_blank" href={item.url} rel={rel}>
                        <img width="398" height="298" alt={item.title} src={formatImage(item.thumbnail, 398, 298)} />
                    </a>
                    <div className={styles.bg} />
                    <div className={styles.pwText}>
                        <a target="_blank" href={item.url} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const card_r_l = item => {
            return (
                <div className={styles.p_r_01}>
                    <a target="_blank" href={item.url} rel={rel}>
                        <img width="198" height="298" alt={item.title} src={formatImage(item.thumbnail, 198, 298)} />
                    </a>
                    <div className={styles.bg} />
                    <div className={styles.pwText}>
                        <a target="_blank" href={item.url} rel={rel}>
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        return (
            <div className={`${styles.YuePhotos} clearfix`}>
                <div className={styles.tit04}>
                    <h3>
                        <a href="http://yue.ifeng.com/pagelist/21897/1/list.shtml" target="_blank" rel={rel}>
                            高清图片
                        </a>
                    </h3>
                </div>
                <div className={styles.space20} />
                <div className={styles.p_l_box}>
                    {content.map((item, index) => {
                        if (index === 0 || index === 1) {
                            return card_l_s(item);
                        } else {
                            return null;
                        }
                    })}
                    {card_l_l(content[4])}
                    {content.map((item, index) => {
                        if (index === 2 || index === 3) {
                            return card_l_s(item);
                        } else {
                            return null;
                        }
                    })}
                </div>
                <div className={styles.p_m_box}>
                    {card_m_l(content[5])}
                    {card_l_l(content[6])}
                </div>
                <div className={styles.p_r_box}>
                    {card_r_l(content[7])}
                    {card_l_s(content[8])}
                </div>
            </div>
        );
    }
}

export default errorBoundary(YuePhotos);
