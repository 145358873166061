import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Search from '@ifeng-fe/ui_pc_search';
import Ad from '@ifeng-fe/ui_pc_ad';

/**
 * for this page
 */
import YueNav from '../../../components/YueNav';

class YueHeader extends React.PureComponent {
    static propTypes = {
        yueNav: PropTypes.array,
        search: PropTypes.array,
        ad: PropTypes.object,
    };

    render() {
        // console.log('YueHeader=', this.props);
        /**
         * 组件分发数据
         */
        const { yueNav, search, ad } = this.props;

        return (
            <div className={styles.YueHeader}>
                <div className={styles.ad_top}>
                    <Ad content={ad} />
                </div>
                <div className={styles.h_searchDiv}>
                    <div className={styles.h_theLogo}>
                        <a href="http://yue.ifeng.com/" target="_blank">
                            <img
                                id="h_f_logo"
                                src="http://p1.ifengimg.com/a/2017/0925/e02b9e736ce8c3fsize74_w694_h128.png"
                                title="凤凰网音乐"
                                alt="凤凰网音乐"
                            />
                        </a>
                    </div>
                    <div className={'clearfix'}>
                        <Chip id="10129" type="static" title="通用搜索" groupName="凤凰音乐首页" content={search}>
                            <Search />
                        </Chip>
                    </div>
                </div>
                <div>
                    <Chip id="30079" type="struct" title="音乐导航栏" groupName="凤凰音乐首页" content={yueNav}>
                        <YueNav />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueHeader);
