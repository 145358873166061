import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from '@ifeng-fe/container_channel/common/client/components/header';
import Footer from '../../components/footer';
import Ad from '@ifeng-fe/ui_pc_ad';
/**
 * for this page
 */
import YueHeader from './yueHeader';
import Content from './content';
import YueFooter from './yueFooter';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    render() {
        // console.log('layout=', this.props);
        /**
         * 组件分发数据
         */
        const {
            nav,
            footer,
            yueNav,
            search,
            yueSlide,
            yueHot,
            yueNews,
            yueAi,
            yueNishizhen,
            yueAiTitle,
            yueNishizhenTitle,
            yueMV,
            yueShow,
            yuePhotos,
            yueAlbums,
            yueCooperativeInstitution,
            topAd,
            fullColAd1,
            fullColAd2,
            fullColAdB,
            adCouplet2,
            adCouplet3,
        } = this.props.content;

        const content = {
            yueSlide,
            yueHot,
            yueNews,
            yueAi,
            yueNishizhen,
            yueAiTitle,
            yueNishizhenTitle,
            yueMV,
            yueShow,
            yuePhotos,
            yueAlbums,
            yueCooperativeInstitution,
        };

        const ads = {
            fullColAd1,
            fullColAd2,
            fullColAdB,
        };

        return (
            <div className={styles.layout}>
                <Header content={nav} />
                <YueHeader yueNav={yueNav} search={search} ad={topAd} />
                <Content content={content} ads={ads} />
                <Chip
                    id="25033"
                    type="static"
                    title="音乐频道-底部合作机构"
                    groupName="正文"
                    content={yueCooperativeInstitution}>
                    <YueFooter />
                </Chip>
                <Chip id="20036" type="struct" title="全站通用页尾导航" groupName="底部" content={footer}>
                    <Footer />
                </Chip>
                <ChipEdit transform={transform} />
                <Ad content={adCouplet2} />
                <Ad content={adCouplet3} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
