import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/public_method';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class YueMV extends React.PureComponent {
    state = {
        isShow: false,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    MouseEnter = {};

    render() {
        // console.log('YueMV=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */

        const listItemBig = (item, index) => {
            const imgUrl = formatImage(item.thumbnail, 320, 320);

            return index === 0 || index === 1 ? (
                <div className={styles.bigPic} key={item.id}>
                    <div className={styles.innerWrap}>
                        <a target="_blank" href={item.url} title={item.title} alt={item.title} rel={rel}>
                            <img src={imgUrl} alt={item.title} width="320" height="320" border="0" title={item.title} />
                            <div className={styles.icon} />
                        </a>
                        <div className={styles.animation}>
                            <div className={styles.textWrap}>
                                <div className={styles.bg} />
                                <div className={styles.text}>
                                    <a target="_blank" href={item.url} title={item.title} alt={item.title} rel={rel}>
                                        {item.title}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null;
        };

        const listItemSmall = (item, index) => {
            const imgUrl = formatImage(item.thumbnail, 150, 150);

            return index !== 0 && index !== 1 ? (
                <div className={styles.smallPic} key={item.id}>
                    <div className={styles.innerWrap01}>
                        <a target="_blank" href={item.url} title={item.title} alt={item.title} rel={rel}>
                            <img src={imgUrl} alt={item.title} width="150" height="150" border="0" title={item.title} />
                            <div className={styles.icon05} />
                        </a>
                        <div className={styles.animation}>
                            <div className={styles.textWrap01}>
                                <div className={styles.bg01} />
                                <div className={styles.text01}>
                                    <a target="_blank" href={item.url} title={item.title} alt={item.title} rel={rel}>
                                        {item.title}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null;
        };

        return (
            <div className={`${styles.YueMV} clearfix`}>
                <div className={styles.tit04}>
                    <h3>
                        <a href="http://yue.ifeng.com/yueMV.shtml" target="_blank">
                            音乐MV
                        </a>
                    </h3>
                </div>
                <div className={`${styles.picWrap} clearfix`}>
                    <div className={styles.col_l}>
                        {content.map((item, index) => {
                            return listItemBig(item, index);
                        })}
                    </div>
                    <div className={`${styles.col_r} clearfix`}>
                        {content.map((item, index) => {
                            return listItemSmall(item, index);
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueMV);
