import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/public_method';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * for this page
 */

class YueShow extends React.PureComponent {
    state = {
        isShow: false,
    };
    static propTypes = {
        content: PropTypes.array,
    };

    MouseEnter = {};

    render() {
        // console.log('YueShow=', this.props);
        const { content } = this.props;
        /**
         * 组件分发数据
         */

        const listItem = (item, index) => {
            return index === 0 ? (
                <li className={styles.current} key={index}>
                    <div className={styles.date01}>{item.date}</div>
                    <div className={styles.listtit01}>
                        <a href={item.url} target="_blank">
                            {item.title}
                        </a>
                    </div>
                </li>
            ) : (
                <li key={index}>
                    <div className={styles.date01}>{item.date}</div>
                    <div className={styles.listtit01}>
                        <a href={item.url} target="_blank">
                            {item.title}
                        </a>
                    </div>
                </li>
            );
        };

        return (
            <div className={`${styles.YueShow} clearfix`}>
                <div className={styles.tit04}>
                    <h3>
                        <a href="javascript:void(0)">演出</a>
                    </h3>
                </div>
                <div className={styles.box}>
                    <ul className={styles.listbg}>
                        {content.map((item, index) => {
                            return listItem(item, index);
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default errorBoundary(YueShow);
